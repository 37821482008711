import "./App.scss";
import LoginPage from "./LoginPage/loginPage";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Amplify from "aws-amplify";
Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_APP_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_DOMINIO,
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: process.env.REACT_APP_LOGIN_URL,
      redirectSignOut: process.env.REACT_APP_LOGIN_URL,
      responseType: "token",
    },
  },
  ssr: true,
});
function App() {
  return (
    <div className="App">
      <LoginPage></LoginPage>
    </div>
  );
}

export default App;
