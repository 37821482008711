import React from "react";
import { Form, Col, Button, Spinner } from "react-bootstrap";
import { Auth, Hub } from "aws-amplify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import AES from "crypto-js/aes";
import { strengthIndicator, strengthColor } from "./strength-password";
import zxcvbn from "zxcvbn";
import { ReactComponent as Logo } from "../images/skfLogo.svg";
import { PostInsertUser } from "./service.users";
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUsername: "",
      username: "",
      password: "",
      newpassword: "",
      currentpassword: "",
      submitted: false,
      validated: false,
      stateSesion: "",
      changePassword: false,
      forgotPassword: false,
      newPasswordBool: false,
      typeLogin: 0,
      error: false,
      login: false,
      matchingPasswords: true,
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { username, password } = this.state;
    this.setState({ validated: true });
    if (username && password) {
      this.loginCognito(username, password);
    }
  };

  handleSubmitPsw = (e) => {
    this.setState({ submitted: true });
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(
          user,
          this.state.currentpassword,
          this.state.newpassword
        );
      })
      .then((data) => {
        // this.setState({ changePassword: false, error: false });
        //   window.location.replace(process.env.REACT_APP_FRONT_URL);
      })
      .catch((err) => {
        console.log(err);
        if (
          err.mensaje == "Attempt limit exceeded, please try after some time."
        )
          this.setState({ error: true, errmessage: err.mensaje });
        if (err.mensaje == "Incorrect username or password")
          this.setState({ error: true, errmessage: err.mensaje });
      });
  };

  completeNewPassword = () => {
    this.setState({ submitted: true });
    if (
      !this.state.password ||
      !this.state.currentUsername ||
      !this.state.newpassword ||
      !this.state.confirmPassword
    )
      return;

    if (this.state.newpassword !== this.state.confirmPassword) {
      this.setState({ matchingPasswords: false });
      return;
    }
    Auth.signIn(this.state.currentUsername, this.state.password)
      .then((user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          Auth.completeNewPassword(
            user, // the Cognito User Object
            this.state.confirmPassword // the new password
          )
            .then((user) => {
              //console.log(user);
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  handleSubmitForgotPsw = (e) => {
    Auth.forgotPasswordSubmit(
      this.state.currentUsername,
      this.state.code,
      this.state.newpassword
    )
      .then((data) => {
        this.setState({ forgotPassword: false });

        window.location.replace(process.env.REACT_APP_FRONT_URL);
      })
      .catch((err) => console.log(err));
  };

  componentDidMount() {
    if (window.location.href.includes("logout=true")) {
      this.logoutCognito();
      window.location.replace(process.env.REACT_APP_LOGIN_URL);
    }

    if (window.location.href.includes("changePsw=true")) {
      this.setState({ changePassword: true });
    }

    window.setInterval(() => {
      this.stateSesion();
    }, 1000);

    window.onbeforeunload = function (e) {
      sessionStorage.setItem("loggedIn", false);
      sessionStorage.setItem("idToken", "");
      sessionStorage.setItem("tokenType", "");
      sessionStorage.setItem("refreshToken", "");
      sessionStorage.setItem("username", "");
      sessionStorage.setItem("expiresIn", "");
    };

    Hub.listen("auth", ({ payload: { event, data } }) => {
      if (event === "signIn") {
        if (data.signInUserSession.idToken.jwtToken) {
          sessionStorage.setItem("loggedIn", true);
          sessionStorage.setItem(
            "idToken",
            data.signInUserSession.idToken.jwtToken
          );

          const expiresIn = moment.unix(
            data.signInUserSession.idToken.payload.exp
          );
          sessionStorage.setItem("expiresIn", expiresIn);
          sessionStorage.setItem("username", data.username);
          sessionStorage.setItem("SKF", true);
          var ciphertext = AES.encrypt(
            JSON.stringify(data.signInUserSession.idToken.jwtToken),
            ")J@NcRfUjXn2r5u8x/A%D*G-KaPdSgVkYp3s6v9y$B&E(H+MbQeThWmZq4t7w!z%"
          ).toString();
          const expiresInTime = Math.floor(Date.now()) + 3600000;

          let url =
            process.env.REACT_APP_FRONT_URL +
            "/login?tokenSKF=" +
            ciphertext +
            "&expiresInSKF=" +
            expiresIn.valueOf();

          window.location.href = url;
        }
      } else if (event === "signIn_failure") {
        PostInsertUser(this.state.username, this.state.password).then((e) => {
   
          if (e.data && e.data.authenticationResult) {
            const { idToken, expiresIn } = e.data.authenticationResult;
            const expiresInTime = Math.floor(Date.now()) + expiresIn * 1000;
            sessionStorage.setItem("expiresIn", expiresIn);
            sessionStorage.setItem("loggedIn", true);
            sessionStorage.setItem("username", data.username);
            sessionStorage.setItem("idToken", idToken);
            var ciphertext = AES.encrypt(
              JSON.stringify(idToken),
              ")J@NcRfUjXn2r5u8x/A%D*G-KaPdSgVkYp3s6v9y$B&E(H+MbQeThWmZq4t7w!z%"
            ).toString();

            let url =
              process.env.REACT_APP_FRONT_URL +
              "/login?tokenSKF=" +
              ciphertext +
              "&expiresInSKF=" +
              expiresInTime;

            window.location.href = url;
          } else {
      
            this.setState({
              error: true,
              errMessage: "User or psw are incorrect.",
            });
            this.setState({ login: false });
          }
        });
      }
    });
  }

  componentWillUnmount() {
    sessionStorage.setItem("loggedIn", false);
    sessionStorage.setItem("idToken", "");
    sessionStorage.setItem("tokenType", "");
    sessionStorage.setItem("refreshToken", "");
    sessionStorage.setItem("username", "");
    sessionStorage.setItem("expiresIn", "");
    document.cookie = "tokenSKF=" + "";
  }

  loginCognito = async (username, password) => {
    this.setState({ login: true });
    let loginResul = undefined;
    try {
      loginResul = await Auth.signIn(username, password);

      if (loginResul?.signInUserSession?.idToken.jwtToken) {
        sessionStorage.setItem("loggedIn", true);
        sessionStorage.setItem(
          "idToken",
          loginResul.signInUserSession.idToken.jwtToken
        );
        const expiresIn = moment.unix(
          loginResul.signInUserSession.idToken.payload.exp
        );

        sessionStorage.setItem("expiresIn", expiresIn);
        sessionStorage.setItem("username", username);
        sessionStorage.setItem("password", password);
        sessionStorage.setItem("SKF", false);

        this.setState({ username, password });
      } else {
        if (loginResul?.challengeName) {
          this.setState({ newPasswordBool: true });
          alert("You must create a new password");
        }
      }
    } catch (error) {}
  };

  logoutCognito = async () => {
    //await Auth.signOut();
    this.handleLogout();
  };

  refreshCognito = async () => {
    try {
      let username = sessionStorage.getItem("username");
      let password = sessionStorage.getItem("password");
      let loginResul = await Auth.signIn(username, password);
      if (loginResul.signInUserSession.idToken.jwtToken) {
        sessionStorage.setItem("loggedIn", true);
        sessionStorage.setItem(
          "idToken",
          loginResul.signInUserSession.idToken.jwtToken
        );
        const expiresIn = moment.unix(
          loginResul.signInUserSession.idToken.payload.exp
        );
        sessionStorage.setItem("expiresIn", expiresIn);
        var ciphertext = AES.encrypt(
          JSON.stringify(loginResul.signInUserSession.idToken.jwtToken),
          ")J@NcRfUjXn2r5u8x/A%D*G-KaPdSgVkYp3s6v9y$B&E(H+MbQeThWmZq4t7w!z%"
        ).toString();
        //    document.cookie = "tokenSKF=" + ciphertext;
        //    document.cookie = "expiresInSKF=" + expiresIn.valueOf();
      }
    } catch (error) {
      console.log("error login aws");
      console.log("error signing in", error);
    }
  };

  stateSesion = () => {
    if (Date.now() < new Date(sessionStorage.getItem("expiresIn")).getTime()) {
      if (this.state.stateSesion !== "Active session")
        this.setState({ stateSesion: "Active session" });
    } else if (this.state.stateSesion !== "Expired Session")
      this.setState({ stateSesion: "Expired Session" });
  };

  handleLogout = () => {
    sessionStorage.setItem("loggedIn", false);
    sessionStorage.setItem("idToken", "");
    sessionStorage.setItem("tokenType", "");
    sessionStorage.setItem("refreshToken", "");
    sessionStorage.setItem("username", "");
    sessionStorage.setItem("expiresIn", "");
    document.cookie = "tokenSKF=" + "";

    this.forceUpdate();
  };

  handleChangePsw = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value, strength: zxcvbn(value).score });

    if (name === "password") {
      // check password
      const strength = strengthIndicator(value);
      const color = strengthColor(strength);
    }
  };

  renderChangePassword() {
    const { sending } = this.props;
    const {
      currentpassword,
      newpassword,
      confirmPassword,
      submitted,
      matchingPasswords,
      error,
      strength,
    } = this.state;

    const passwordLength = newpassword.length;
    const passwordStrong = strength >= this.minStrength;
    const passwordLong = passwordLength > this.thresholdLength;
    const counterClass = [
      "badge badge-pill",
      passwordLong
        ? passwordStrong
          ? "badge-success"
          : "badge-warning"
        : "badge-danger",
    ]
      .join(" ")
      .trim();

    // password strength meter is only visible when password is not empty
    const strengthClass = [
      "strength-meter mt-2",
      passwordLength > 0 ? "visible" : "invisible",
    ]
      .join(" ")
      .trim();

    return (
      <div>
        <div className="logo">
          <Logo></Logo>
        </div>
        <div className="full authBG">
          <div className="loginBox">
            <div className="full">
              <div className="centerBox">
                <span className="SKFH1">Change Password</span>
                <br></br>
                <br></br>
                <div
                  className={
                    "form-group " +
                    (submitted && !newpassword ? " has-error" : "")
                  }
                >
                  <input
                    type="password"
                    className="form-control inputlogin"
                    placeholder="Current password"
                    name="currentpassword"
                    value={currentpassword}
                    onChange={this.handleChangePsw}
                  />

                  {submitted && !currentpassword && (
                    <div className="help-block errortext">
                      Currnet Password is required.
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group " +
                    (submitted && !newpassword ? " has-error" : "")
                  }
                >
                  <input
                    type="password"
                    className="form-control inputlogin"
                    placeholder="New password"
                    value={newpassword}
                    name="newpassword"
                    onChange={this.handleChangePsw}
                  />
                  {submitted && !newpassword && (
                    <div className="help-block errortext">
                      Password is required.
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group" +
                    (submitted && !confirmPassword ? " has-error" : "")
                  }
                >
                  <input
                    type="password"
                    className="form-control inputlogin"
                    placeholder="Confirm new password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={this.handleChangePsw}
                  />
                  {submitted && !confirmPassword && (
                    <div className="help-block errortext">
                      Password needs to be confirmed.
                    </div>
                  )}
                </div>
                Password strength
                <div className={strengthClass}>
                  <div
                    className="strength-meter-fill"
                    data-strength={strength}
                  ></div>
                </div>
                {submitted &&
                  newpassword &&
                  confirmPassword &&
                  !(newpassword == confirmPassword) && (
                    <div className="help-block errortext">
                      Passwords need to match.
                    </div>
                  )}
                {submitted &&
                  newpassword &&
                  confirmPassword &&
                  !(newpassword.length > 6) &&
                  !(confirmPassword.length > 6) && (
                    <div className="help-block">
                      Passwords must have length greater than or equal to 6 .
                    </div>
                  )}
                {submitted &&
                  error &&
                  newpassword.length > 6 &&
                  confirmPassword.length > 6 && (
                    <div className="help-block">Old passwords incorrect .</div>
                  )}
                <div className="form-group centerText customMargin">
                  <button
                    className="btn btn-primary button-change-password"
                    onClick={() => this.handleSubmitPsw()}
                  >
                    Change password{" "}
                  </button>

                  <button
                    style={{ marginLeft: 20 }}
                    className="btn btn-primary button-change-password"
                    onClick={() => {
                      this.setState({ changePassword: false });
                      window.location.replace(process.env.REACT_APP_FRONT_URL);
                    }}
                  >
                    Back{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  sendEmail = () => {
    alert("you will receive a code in your email");
    Auth.forgotPassword(this.state.currentUsername)
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  };

  renderForgotPassword() {

    const { sending } = this.props;
    const {
      currentUsername,
      newpassword,
      confirmPassword,
      submitted,
      matchingPasswords,
      strength,
      code,
    } = this.state;
    console.log(this.state)
    const passwordLength = newpassword.length;
    const passwordStrong = strength >= this.minStrength;
    const passwordLong = passwordLength > this.thresholdLength;

    const counterClass = [
      "badge badge-pill",
      passwordLong
        ? passwordStrong
          ? "badge-success"
          : "badge-warning"
        : "badge-danger",
    ]
      .join(" ")
      .trim();

    // password strength meter is only visible when password is not empty
    const strengthClass = [
      "strength-meter mt-2",
      passwordLength > 0 ? "visible" : "invisible",
    ]
      .join(" ")
      .trim();
   
    return (
      <div>
        <div className="logo">
          <Logo></Logo>
        </div>
        <div className="full authBG">
          <div className="forgetBox">
            <div className="full">
              <div className="centerBox">
                <span className="SKFH1 clickable-block">
                  {" "}
                  {this.state.newPasswordBool
                    ? "New Password"
                    : "Forgot Password"}{" "}
                </span>
                <br></br>
                <br></br>
                <div
                  className={
                    "form-group " +
                    (submitted && !newpassword ? " has-error" : "")
                  }
                >
                  <input
                    style={
                      this.state.forgotPassword
                        ? { width: "60%", float: "left" }
                        : {}
                    }
                    type="text"
                    className="form-control inputlogin"
                    placeholder="Username"
                    id="Username"
                    name="currentUsername"
                    value={currentUsername}
                    onChange={this.handleChangePsw}
                  />
                  {this.state.forgotPassword && (
                    <button
                      style={{ width: "30%", float: "left", marginTop: 10 }}
                      className="btn btn-primary button-change-password"
                      onClick={() => this.sendEmail()}
                    >
                      Send email
                    </button>
                  )}
                  {submitted && !currentUsername && (
                    <div className="help-block errortext">
                      Currnet Username is required.
                    </div>
                  )}
                  {this.state.forgotPassword && (
                    <div>
                      {" "}
                      <br></br>
                      <br></br>
                      <br></br>
                      <input
                        type="text"
                        className="form-control inputlogin"
                        placeholder="Code email"
                        id="code"
                        name="code"
                        value={code}
                        onChange={this.handleChangePsw}
                      />
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group " +
                    (submitted && !newpassword ? " has-error" : "")
                  }
                >
                  <input
                    type="password"
                    className="form-control inputlogin"
                    placeholder="New password"
                    value={newpassword}
                    name="newpassword"
                    onChange={this.handleChangePsw}
                  />
                  {submitted && !newpassword && (
                    <div className="help-block errortext">
                      Password is required.
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group" +
                    (submitted && !confirmPassword ? " has-error" : "")
                  }
                >
                  <input
                    type="password"
                    className="form-control inputlogin"
                    placeholder="Confirm new password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={this.handleChangePsw}
                  />
                  {submitted && !confirmPassword && (
                    <div className="help-block errortext">
                      Password needs to be confirmed.
                    </div>
                  )}
                </div>
                Password strength
                <div className={strengthClass}>
                  <div
                    className="strength-meter-fill"
                    data-strength={strength}
                  ></div>
                </div>
                {submitted &&
                  newpassword &&
                  confirmPassword &&
                  !matchingPasswords && (
                    <div className="help-block errortext">
                      Passwords need to match.
                    </div>
                  )}
                <div className="form-group centerText customMargin">
                  <button
                    className="btn btn-primary button-change-password"
                    onClick={
                      !this.state.newPasswordBool
                        ? this.handleSubmitForgotPsw
                        : this.completeNewPassword
                    }
                  >
                    Change password{" "}
                  </button>

                  <button
                    style={{ marginLeft: 20 }}
                    className="btn btn-primary button-change-password"
                    onClick={() =>
                      this.setState({
                        forgotPassword: false,
                        newpassword: false,
                      })
                    }
                  >
                    Back{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderLogin() {
    const { loginState } = this.props;
    const { alert } = this.props;

    const { submitted, validated } = this.state;
    const lsloggedin = sessionStorage.getItem("loggedIn");
    const lsidToken = sessionStorage.getItem("idToken");
    let isLoggedIn = lsidToken && lsloggedin;
    return (
      <div>
        <div className="logo">
          <Logo></Logo>
        </div>
        <div className="full authBG">
          <div className="loginBox">
            <div className="loginTitle">
              <span className="SKFH1">Welcome to SKF</span>
              <br />
              <span className="SKFH1">Rail CoMo Visualization</span>
            </div>
            {isLoggedIn ? (
              <div>
                <br></br>
                <div>
                  You are already logged in as:{" "}
                  <span className="SKFH3">
                    {sessionStorage.getItem("username")}
                  </span>{" "}
                </div>
                <div>
                  Sesion:{" "}
                  <span className="SKFH3">{this.state.stateSesion}</span>{" "}
                </div>{" "}
                <br></br>
                <br></br>
                <Button
                  type="submit"
                  className="btn btn-primary buttonlogin"
                  onClick={this.handleLogout}
                >
                  Logout{" "}
                  {loginState && <FontAwesomeIcon icon={faSpinner} spin />}
                </Button>
                <br></br>
                <br></br>
                {this.state.stateSesion == "Expired Session" ? (
                  <Button
                    type="submit"
                    className="btn btn-primary buttonlogin"
                    onClick={this.refreshCognito}
                  >
                    Refresh Log In{" "}
                    {loginState && <FontAwesomeIcon icon={faSpinner} spin />}
                  </Button>
                ) : (
                  <div />
                )}
              </div>
            ) : (
              <Form
                noValidate
                validated={validated}
                onSubmit={(e) => this.handleSubmit(e)}
              >
                <Form.Row>
                  <Form.Group as={Col} controlId="validationCustom01">
                    <Form.Label className="fontMin noMarginBottom customMargin">
                      {" "}
                      Email{" "}
                    </Form.Label>
                    <Form.Control
                      className="form-control inputlogin"
                      required
                      type="text"
                      name="username"
                      value={this.state.username}
                      onChange={this.handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      Username is required{" "}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="validationCustom01">
                    <Form.Label className="fontMin noMarginBottom">
                      {" "}
                      Password{" "}
                    </Form.Label>
                    <Form.Control
                      className="form-control inputlogin"
                      required
                      value={this.state.password}
                      onChange={this.handleChange}
                      type="password"
                      name="password"
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      Password is required{" "}
                    </Form.Control.Feedback>
                    {submitted && alert && alert.message && (
                      <div className="msgError">{alert.message}</div>
                    )}
                    {this.state.error && (
                      <div className="msgError">{this.state.errMessage}</div>
                    )}
                  </Form.Group>
                </Form.Row>

                <div className="form-group centerText customMargin">
                  <Button
                    type="submit"
                    className="btn btn-primary buttonlogin"
                    disabled={this.state.login}
                  >
                    {this.state.login ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Log in"
                    )}

                    {loginState && <FontAwesomeIcon icon={faSpinner} spin />}
                  </Button>

                  <Button
                    disabled={this.state.login}
                    style={{ marginLeft: 20 }}
                    className="btn btn-primary buttonlogin"
                    onClick={async () => {
                      let result = await Auth.federatedSignIn({
                        customProvider: "SKF",
                      });
                    }}
                  >
                    {this.state.login ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "  SKF log in"
                    )}
                    

                    {loginState && <FontAwesomeIcon icon={faSpinner} spin />}
                  </Button>
                </div>

                <div className="centerText">
                  <span
                    className="fontMin clickable-block"
                    onClick={() => this.setState({ forgotPassword: true })}
                  >
                    Forgot your password?
                  </span>
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (
      !this.state.changePassword &&
      !this.state.forgotPassword &&
      !this.state.newPasswordBool
    )
      return this.renderLogin();
    else if (this.state.changePassword) return this.renderChangePassword();
    else return this.renderForgotPassword();
  }
}

export default LoginPage;
