import axios from "axios";

const PostInsertUser = async (userName, password) => {
  const url = `${process.env.REACT_APP_API_URL}/api/users/InsertUser`;
  return axios({
    method: "post",
    url,

    data: {
      userName,
      password,
    },
  }).catch((e) => {
    return 500;
  });
};
export { PostInsertUser };
